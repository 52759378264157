#write{
    background-color: black;
}
.textNik {
    font-style: oblique;
    color: white;
    font-size: 16px;
}
.card-body{
    background-color: black;
}
.card-text:last-child{
    color: white;
    background-color: black;
    border: 1px solid black;
    padding: 15px;
}

#Bracelet12{
    width: 240px;
    height: 270px;
    border: 1px solid white;
}
#BraceleT10{
    width: 240px;
    height: 270px;
    border: 1px solid white;
}
