.logo {
    width: 200px;
    height: 40px;
}
#menuStyle{
    margin-top: 30px;
}
.flag-fonts {
    font-size: 35px;
    margin-top: 70px;
    text-align:center;
}
.dropdown {
    text-align: right;
}



