
#pic {
    height: 300px;
    padding: 7px 10px;
    border: 4px solid dodgerblue;
    margin-top: 20px;

}

#text {
    width: 380px;
    height: 300px;
    -webkit-box-shadow: inset 1px 1px 1px 0px dodgerblue;
    font-size: 15px;
    font-family: tahoma;
    padding: 7px 10px;
    margin: 10px;
    border: 4px solid dodgerblue;
    padding-left: 1.8rem;
    margin-top: 30px;
}

#adminText {
    font-size: small;
    position: absolute;
    left: 30px;
    top: 115px;
    right: 5px;
    bottom: 5px;

}

.storiesName {
    color: white;
    font-style: oblique;
    margin-top: 60px;
}

#listenMenu {
    top: -50px;
    margin-left: 530px;
}

