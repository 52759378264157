
#write{
    background-color: black;
}
.textNik {
    font-style: oblique;
    color: white;
    font-size: 16px;
}
img {
    border-radius: 5px;
    padding: 5px;
    width: 220px;
    height: 300px;
}
.container{
    color: white;
}
