
.img-btn {
    background-color: #555;
    text-align: center;
    color: white;
    border: none;
    cursor: pointer;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #004A7F;
    -webkit-border-radius: 10px;
    border-radius: 80px;
    position: absolute;
    top: 51%;
    left: 53%;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Arial;
    font-size: 30px;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    -webkit-animation: glowing 1500ms infinite;
    -moz-animation: glowing 1500ms infinite;
    -o-animation: glowing 1500ms infinite;
    animation: glowing 1500ms infinite;
}
.img-btn:hover {
    background-color: black;
}

@-webkit-keyframes glowing {
    0% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
    50% { background-color: #FF0000; -webkit-box-shadow: 0 0 40px #FF0000; }
    100% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
}

@-moz-keyframes glowing {
    0% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
    50% { background-color: #FF0000; -moz-box-shadow: 0 0 40px #FF0000; }
    100% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
}

@-o-keyframes glowing {
    0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
    50% { background-color: #FF0000; box-shadow: 0 0 40px #FF0000; }
    100% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
}

@keyframes glowing {
    0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
    50% { background-color: #FF0000; box-shadow: 0 0 40px #FF0000; }
    100% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
}
