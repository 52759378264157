@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width: $circle-size;
  height: $circle-size;
  border-radius: 50%;
  padding: 0;
  list-style: none;
  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -($item-size / 2);
    width: $item-size;
    height: $item-size;

    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg) translate($circle-size / 2) rotate($rot * -1deg);
      }
      $rot: $rot + $angle;
    }
  }
}
.circle-container {
  @include on-circle($item-count: 10, $circle-size: 19em, $item-size: 6em);
  margin: 8em auto 0;
}
.ReadText{
  color: white;
  background-color: black;
  margin-top: 60px;
  border: 3px solid #007bff;
  padding: 15px;
  -webkit-text-fill-color: white;
  opacity: 1; /* required on iOS */
}
.adminImage{
width: 130px;
  margin-top: -95px;
  margin-left: -225px;
}
